<template>
  <div>
    <el-form label-width="70px"
             :model="form">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       @change="search"
                       clearable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="add('')">新增</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- <Table ref="TablesRef"
           :tableData="tableData">
    </Table> -->
    <TreeTable :stripe="true"
               :boder="true"
               :data="tableData"
               v-if="tableData.length"
               :tree-type="true"
               :columns="tableColumns"
               :children-prop="config.child"
               :expand-type="false"
               :show-row-hover="true"
               :selection-type="false">
      <template slot="status"
                slot-scope="{row}">
        <div :class="getClass('status',row.status)">
          {{getValue('status',row.status)}}
        </div>
      </template>
      <template slot="opt"
                slot-scope="{row}">
        <el-tooltip effect="dark"
                    v-if="row.level == 1"
                    content="添加二级目录"
                    placement="top-start">
          <img :src="imgs['edit']"
               @click="add(row.capability_id,row.subject_id)"
               class="operyion_img">
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="修改"
                    placement="top-start">
          <img :src="imgs['edit']"
               @click="editRow(row)"
               class="operyion_img">
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="删除"
                    placement="top-start">
          <img :src="imgs['del']"
               @click="del(row)"
               class="operyion_img">
        </el-tooltip>
      </template>
    </TreeTable>
    <!-- <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" /> -->
    <EditForm ref="EditFormRef" />
  </div>
</template>

<script>
// import Form from './components/form.vue'
import EditForm from './components/editForm.vue'
import { getAllSubject } from '@/api/subject.js'
import { getTagList, delTag } from '@/api/abilityTag.js'

export default {
  data () {

    return {
      imgs: {
        del: require('@/assets/icons/icon-删除@2x.png'),
        edit: require('@/assets/icons/icon-修改@2x.png'),
        reset: require('@/assets/icons/icon-重置密码.png'),
        look: require('@/assets/icons/icon-查看@2x.png'),
        fabu: require('@/assets/icons/发布.png'),
      },
      tableColumns: [
        { prop: 'capability_id', align: 'center', label: '编号', width: '', },
        { prop: 'capability_name', align: 'center', label: '核心素养', width: '', },
        { prop: 'subject_name', align: 'center', label: '科目', width: '' },
        { prop: 'status', align: 'center', label: '状态', width: '', showToopic: false, isStatus: true, type: "template", template: 'status' },
        { prop: 'serial_no', align: 'center', label: '排序', width: '', showToopic: false },
        {
          prop: 'opt', align: 'center', label: '操作', width: '180', type: 'template', template: 'opt', btns: [
          ]
        },
      ],
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        subject_id: '',
        keywords: '',
      },
      config: { hasChildren: 'child', child: 'child' },
    }
  },
  components: {
    // Form,
    EditForm
  },
  mounted () {
    this.getSubjectList()
    // this.setTableColumns()
  },
  methods: {
    getClass (prop, value) {
      if (prop == 'status') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '显示' : '隐藏'
      }
    },
    async getSubjectList () {
      const { data } = await getAllSubject()
      this.subjectList = data.list
    },
    // setTableColumns () {
    //   this.$refs.TablesRef.setColumns(this.tableColumns)
    // },
    async initData (page, limit) {
      const { data } = await getTagList({
        subject_id: this.form.subject_id,
        page,
        limit,
      })
      this.tableData = data.list
      this.totalElements = data.count
    },
    search () {
      this.initData(this.page, this.size)
      // this.$refs.pagination.toFirstPage()
    },
    refresh () {
      // this.initData(this.page,this.size)
      this.$refs.pagination.shuaxin()
    },
    editRow (row) {
      let form = {
        level: row.level,
        capability_id: row.capability_id,
        capability_parent_id: row.capability_parent_id,
        capability_name: row.capability_name,
        subject_id: row.subject_id,
        status: row.status,
        serial_no: row.serial_no
      }
      this.$refs.EditFormRef.text = '修改'
      if (row.level == 2) {
        this.$refs.EditFormRef.getTList(row.subject_id)
      }
      this.$refs.EditFormRef.dialogVisible = true
      this.$refs.EditFormRef.form = form

    },
    add (id = '', subject_id) {
      console.log("点击");
      let form = {
        capability_id: '',
        capability_parent_id: id,
        capability_name: '',
        subject_id: subject_id,
        status: 1,
        serial_no: ''
      }
      if (id === '') {
        form.level = 1
      } else if (subject_id !== '') {
        form.level = 2;
      }
      if (form.level === 2) {
        console.log(3333);
        this.$refs.EditFormRef.getTList(subject_id)
      }
      this.$refs.EditFormRef.dialogVisible = true
      this.$refs.EditFormRef.text = '新增'
      this.$refs.EditFormRef.form = form
    },
    del (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delTag({
          capability_id: row.capability_id,
          capability_parent_id: row.capability_parent_id
        })
        this.$notify({
          title: '提示',
          message: '删除成功!',
          type: 'success'
        });
        this.search()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.line {
  display: flex;
  .el-button {
    margin-left: 5px;
  }
}
.block {
  display: flex;
}
</style>