import { render, staticRenderFns } from "./editForm.vue?vue&type=template&id=590a348d&scoped=true&"
import script from "./editForm.vue?vue&type=script&lang=js&"
export * from "./editForm.vue?vue&type=script&lang=js&"
import style0 from "./editForm.vue?vue&type=style&index=0&id=590a348d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590a348d",
  null
  
)

export default component.exports